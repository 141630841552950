import Auth from '@/modules/Auth';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';

import { getTrackingInformationForBE } from '@/utility/analytics';
import { checkIsCommunityCheckoutRequired } from '@/utility/communityHelpers';
import { getTimezoneId } from '@/utility/dateHelper';
import { checkIfIndexable, openInNewTab } from '@/utility/helpers';
import { t } from '@/utility/localization';
import {
  get1on1SessionLandingPageFullRoute,
  getCommunityCheckoutRoute
} from '@/utility/routesHelper';

import { SIGNUP_ORIGIN } from '@/features/SignUpPopup/constants';
import CompleteYourProfileModalWrapper, {
  getIsUserProfileComplete
} from '@/features/User/CompleteYourProfileModal/CompleteYourProfileModalWrapper';

import { unifiedCheckoutSignup } from '@/services/communitiesService';

import { useAuthContext } from '@/contexts/AuthContext';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import BackAndShareBtnBar from '@/components/common/BackAndShareBtnBar/BackAndShareBtnBar';
import PageMetaTags from '@/components/common/PageMetaTags';
import {
  showErrorToast,
  showSuccessToast
} from '@/components/common/ToastContainer';
import WithPendingCommMembershipApprovalModal from '@/components/features/CommunityPage/components/home/WithPendingCommMembershipApprovalModal';
import WithWelcomeToCommunitySplashScreen from '@/components/features/CommunityPage/components/home/WithWelcomeToCommunitySplashScreen';
import GenericNavBar from '@/components/features/Navbar/GenericNavbar';
import NPLGlassImageBackground from '@/components/npl/NPLGlassImageBackground';

import useShallowRouterReplace from '@/hooks/router/useShallowRouterReplace';
import useCommonPublicPageData from '@/hooks/useCommonPublicPageData';
import { DISCOUNT_ENTITY_TYPE } from '@/hooks/useDiscount';
import useEntityPrice from '@/hooks/useEntityPrice';
import useQueryParams from '@/hooks/useQueryParams';
// import MobileNotAcceptingBookingsCard from './MobileNotAcceptingBookingsCard';
import {
  getCheckoutRequestor,
  getSourceInfo,
  getUnifiedSignupMemberInfo,
  getUnifiedSignupPaymentProvider
} from '@/hooks/utils/communityMembersUtils';

import { CheckoutTypes } from '@/pages/checkout-global/consts';

import use1on1SessionSlots from '../../hooks/use1on1SessionSlots';
import useOneOnOneLandingPageAnalytics from '../../hooks/useOneOnOneLandingPageAnalytics';
import useUserUpcoming1on1Booking from '../../hooks/useUserUpcoming1on1Booking';
import { getNextAvailableSessionDateString } from '../../utils';
import AboutOneOnOneSession from './AboutOneOnOneSession';
import AboutTheHost from './AboutTheHost';
import BannerImage from './BannerImage';
import BookAvailableSlotsCard from './BookAvailableSlotsCard';
import HostProfileImage from './HostProfileImage';
import MobileNoSlotsAvailable from './MobileNoSlotsAvailable';
import MobileNotAcceptingBookingsCard from './MobileNotAcceptingBookingsCard';
import MobileUpcomingBookingCard from './MobileUpcomingBookingCard';
import NoSlotsAvailableCard from './NoSlotsAvailableCard';
import NotAcceptingBookingsCard from './NotAcceptingBookingsCard';
import Overview from './Overview';
import SessionBookingSuccessModal from './SessionBookingSuccessModal';
import UpcomingBookingCard from './UpcomingBookingCard';
import MobileNextAvailableSessionCard from './mobile-slot-selection/MobileNextAvailableSessionCard';
import SelectBookingSlotPopup from './mobile-slot-selection/SelectBookingSlotPopup';

const PublicPageFooter = dynamic(
  () => import('@/components/common/PublicPageFooter')
);
const CommunitySignupModal = dynamic(
  () => import('@/features/CommunitySignupModal/CommunitySignupModal'),
  {
    ssr: false
  }
);
const BecomeAnAffiliateCard = dynamic(
  () => import('@/features/Affiliates/components/BecomeAnAffiliateCard')
);
const YoureAnAffiliateOfTheProductCard = dynamic(
  () =>
    import(
      '@/features/Affiliates/components/YoureAnAffiliateOfTheProductCard'
    )
);
const BecomeAnAffiliateModal = dynamic(
  () => import('@/features/Affiliates/components/BecomeAnAffiliateModal')
);

const OneOnOneLandingPage = (props) => {
  const { templateData, templateContentType } = props?.pageInfo || {};
  const userCommunitiesData = props?.userCommunitiesData;
  const { communityInfo, resourceInfo } = templateData || {};
  const { replaceRoute } = useShallowRouterReplace();
  const communityId = communityInfo?._id;

  // analytics functions
  const {
    trackLeftNavArrowClickOnBookingDays,
    trackRightNavArrowClickOnBookingDays,
    trackChangeBookingDate,
    trackChangeBookingTime,
    trackChangeTimezone,
    trackBookBtnClick,
    trackJoinBtnClick,
    trackMobileViewDatesClick
  } = useOneOnOneLandingPageAnalytics({ communityInfo, resourceInfo });

  // State variables
  const [isBookingSlot, setIsBookingSlot] = useState(false);

  const [selectedTimeZoneId, setSelectedTimeZoneId] =
    useState(getTimezoneId());
  // Add selected start and end time to a state variable
  const [selectedSessionStartTime, setSelectedSessionStartTime] =
    useState(null);
  const [selectedSessionEndTime, setSelectedSessionEndTime] =
    useState(null);

  // Mobile only - bottom popup for date selection.
  const [showSelectBookingSlotPopup, setShowSelectBookingSlotPopup] =
    useState(false);

  const [
    showSessionBookingSuccessModal,
    setShowSessionBookingSuccessModal
  ] = useState(false);

  const router = useRouter();

  const { currentRouterQuery } = useQueryParams();

  const {
    withBookingSuccessModal,
    showSignupSuccessPrompt,
    upsellIdentityCode
  } = currentRouterQuery;
  const { isLoggedIn, user } = useAuthContext();

  const { isGtEqLg } = useWindowWidthContext();

  const { sessionSlots, isFetchingSlots, areSlotsAvailable } =
    use1on1SessionSlots({
      communityId,
      oneOnOneSessionId: resourceInfo?._id,
      timezoneId: selectedTimeZoneId
    });

  const { hasUpcomingBookings, upcomingBooking, refreshUpcomingBookings } =
    useUserUpcoming1on1Booking({
      isLoggedIn,
      oneOnOneSessionId: resourceInfo?._id
    });

  const { discountedCodeData } = useEntityPrice({
    communityCode: communityInfo?.code,
    entityId: resourceInfo?._id,
    entityType: DISCOUNT_ENTITY_TYPE.SESSION
  });

  const {
    isCommunityAdmin,
    isPendingApproval,
    isCommunityMember,
    isCommunityAffiliate,
    isSignUpModalOpened,
    isSignupModalOnlyForCommunity,
    isSignupModalToBecomeAffiliate,
    openSignUpModal,
    onSignUpPopupClose,
    loadingUserRoles,
    refetchUserRoleInCommunity,
    setIsSignUpModalOpened,
    // affilaite program
    joinAsAffiliateProps,
    setAffiliateInfo
  } = userCommunitiesData || {};

  const commonPublicPageData = useCommonPublicPageData({
    communityInfo
  });

  const {
    pricingData,
    priceTagText,
    discountCodeFromQueryParams,
    affiliateCode
  } = commonPublicPageData;

  // Affiliate Program Data start
  const isAffiliateProduct = Boolean(
    resourceInfo?.affiliateInfo?.isAffiliateProduct
  );
  const {
    // card
    showJoinAsAffiliateCard,

    // modal
    showJoinAsAffiliateModal,
    onOpenJoinAsAffiliateModal,
    onCloseJoinAsAffiliateModal,

    // join as affiliate fn
    isSubmittingJoinAsAffiliate,
    onJoinAsAffiliate
    // joinAsAffiliateError
  } = joinAsAffiliateProps;

  useEffect(() => {
    if (resourceInfo?.affiliateInfo) {
      setAffiliateInfo(resourceInfo?.affiliateInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInfo?.affiliateInfo]);
  // Affiliate Program Data end

  // Page metadata props
  const sessionPageLink = get1on1SessionLandingPageFullRoute({
    communityData: communityInfo,
    oneOnOneSessionData: resourceInfo
  });
  const metaTagProps = {
    title: resourceInfo?.title,
    description: resourceInfo.description,
    imageUrl: resourceInfo?.thumbnail,
    url: sessionPageLink,
    isIndexable:
      checkIfIndexable(communityInfo) && checkIfIndexable(resourceInfo)
  };
  const oneOnOneSessionPrice =
    resourceInfo?.priceDetails?.localAmount ??
    resourceInfo?.priceDetails?.amount;

  const discountedOneOnOneSessionPrice = useMemo(() => {
    if (discountedCodeData) {
      const { type, value } = discountedCodeData;
      if (type === 'percentage') {
        return parseFloat(
          Number(oneOnOneSessionPrice * (1 - value / 100)).toFixed(2)
        );
      }
    }

    return null;
  }, [discountedCodeData, oneOnOneSessionPrice]);

  const hostName = resourceInfo?.hostInfo?.hostName;

  const isProfileComplete =
    user?.learner?.firstName || user?.learner?.lastName;

  const isCommunityCheckoutRequired =
    !isCommunityMember && checkIsCommunityCheckoutRequired(communityInfo);

  const isOneOnOneSessionCheckoutRequired =
    resourceInfo?.access === 'paid' ||
    resourceInfo?.applicationConfigDataFields?.length;

  const isCheckoutRequired =
    isCommunityCheckoutRequired || isOneOnOneSessionCheckoutRequired;

  const entityMetadata = {
    sessionStartTime: selectedSessionStartTime,
    sessionEndTime: selectedSessionEndTime,
    sessionTz: selectedTimeZoneId
  };

  // To ensure that it does not overlap with profile completion modal
  const showJoinAsAffiliateModalExtra = isCommunityMember
    ? showJoinAsAffiliateModal && getIsUserProfileComplete(user)
    : showJoinAsAffiliateModal;

  const showYouAreAnAffiliateCard =
    isCommunityAffiliate && isAffiliateProduct;

  // const handleGoToManage1on1Page = () => {
  //   router.push(
  //     get1on1SessionManagementUrl({
  //       id: resourceInfo?._id,
  //       activeCommunityId: communityInfo?._id
  //     })
  //   );
  // };

  const handleGoToCheckout = (params = {}) => {
    const {
      sessionStartTime,
      sessionEndTime,
      ignoreSessionCheckout = false,
      isForAffiliateSignup = false
    } = params;

    const checkoutRoute = getCommunityCheckoutRoute({
      communityId: communityInfo?._id,
      communityCode: communityInfo?.code,
      requestor:
        currentRouterQuery?.requestor ||
        getCheckoutRequestor({ communityInfo }),
      sourceInfo: ignoreSessionCheckout
        ? {}
        : getSourceInfo({
            signUpOrigin: SIGNUP_ORIGIN.ONE_ON_ONE_SESSION_PAGE,
            resourceInfo,
            entityMetadata: {
              sessionStartTime,
              sessionEndTime,
              sessionTz: selectedTimeZoneId
            }
          }),
      entityDiscountCode: discountCodeFromQueryParams,
      upsellIdentityCode: upsellIdentityCode,
      affiliateCode,
      withJoinAsAffiliateCb: Boolean(isForAffiliateSignup),
      recipientObjectId: currentRouterQuery?.recipientObjectId
    });

    router.push(checkoutRoute);
  };

  const handleFreeSessionBooking = async (
    params,
    withCommunitySignup = false
  ) => {
    const { sessionStartTime, sessionEndTime } = params;

    const userData = Auth.getUserDataSession();

    let items = [];

    if (withCommunitySignup) {
      const communitySignupItem = {
        type: CheckoutTypes.SUBSCRIPTION
      };
      items.push(communitySignupItem);
    }

    const sessionSignupItem = {
      type: CheckoutTypes.SESSION,
      entityId: resourceInfo?._id,
      sessionStartTime: sessionStartTime,
      sessionEndTime: sessionEndTime,
      timezoneOfAttendee: selectedTimeZoneId
    };

    items.push(sessionSignupItem);

    let payload = {
      communityCode: communityInfo?.code,
      timezone: getTimezoneId(),
      trackingData: getTrackingInformationForBE(),
      requestor: getCheckoutRequestor({ communityInfo }),
      memberInfo: getUnifiedSignupMemberInfo(userData),
      paymentProvider: getUnifiedSignupPaymentProvider(communityInfo),
      items
    };

    if (affiliateCode) {
      payload.affiliateCode = affiliateCode;
    }

    const resp = await unifiedCheckoutSignup(payload);

    if (resp.error) {
      showErrorToast(resp.error);
      setIsBookingSlot(false);
      return;
    }

    if (
      !resp.error &&
      resp?.data?.entityStatus.sessionBooked && // confirm if session is booked.
      (withCommunitySignup
        ? resp?.data?.enrollmentStatus?.memberEnrolled
        : true) // check for membership elrolled only if withCommunitySignup is true
    ) {
      refreshUpcomingBookings();
      onSignUpPopupClose();

      // isProfileComplete = exisiting user, then show booking success
      // else user should fill up profile name first then
      // show booking success modal
      if (isProfileComplete) {
        setShowSessionBookingSuccessModal(true);
      }
    }
  };

  const handlePostFreeCommunitySignup = () => {
    if (isSignupModalOnlyForCommunity) {
      refetchUserRoleInCommunity();
      // if signup was for affiliate, redirect to affiliate products page
      if (isSignupModalToBecomeAffiliate) {
        onOpenJoinAsAffiliateModal();
      }

      // Do nothing if signup was only for community
      return;
    }

    const params = {
      sessionStartTime: selectedSessionStartTime,
      sessionEndTime: selectedSessionEndTime
    };
    if (isOneOnOneSessionCheckoutRequired) {
      handleGoToCheckout(params);
      return;
    }

    const withCommunitySignup = false;
    handleFreeSessionBooking(params, withCommunitySignup);
    refetchUserRoleInCommunity();
  };

  const handleJoinBtnClick = () => {
    const joinLink =
      upcomingBooking.sessionObjectId.location.locationValue;
    openInNewTab(joinLink);

    trackJoinBtnClick();
  };

  const handleChangeTimezone = (timezoneId) => {
    setSelectedTimeZoneId(timezoneId);
    trackChangeTimezone({ timezoneId });
  };

  const handleFreeCommunitySignup = async (params = {}) => {
    const { isForAffiliateSignup } = params;
    if (isLoggedIn) {
      let payload = {
        communityCode: communityInfo?.code,
        timezone: getTimezoneId(),
        trackingData: getTrackingInformationForBE(),
        requestor: getCheckoutRequestor({ communityInfo }),
        paymentProvider: getUnifiedSignupPaymentProvider(communityInfo),
        memberInfo: getUnifiedSignupMemberInfo(user),
        items: [
          {
            type: 'SUBSCRIPTION'
          }
        ]
      };

      if (currentRouterQuery.affiliateCode) {
        payload.affiliateCode = currentRouterQuery.affiliateCode;
      }

      const { data, error } = await unifiedCheckoutSignup(payload);

      if (error) {
        showErrorToast(error);
        return;
      }

      if (data?.enrollmentStatus?.memberEnrolled) {
        showSuccessToast(t('successfully-joined-community'));
        refetchUserRoleInCommunity();

        if (isForAffiliateSignup) {
          onOpenJoinAsAffiliateModal();
        }
      }

      return;
    }

    openSignUpModal({
      onlySignupToCommunity: true,
      isSignupModalToBecomeAffiliate: Boolean(isForAffiliateSignup)
    });
  };

  // Used when logged in user wants to join the community for affiliate without purcharing product
  const handleJoinAsMember = (
    params = {
      isForAffiliateSignup: false
    }
  ) => {
    const { isForAffiliateSignup } = params;
    // is community checkout required.
    const isCommunityCheckoutRequired =
      !isCommunityMember &&
      checkIsCommunityCheckoutRequired(communityInfo);

    if (isCommunityCheckoutRequired) {
      // go to checkout
      handleGoToCheckout({
        ignoreSessionCheckout: true,
        isForAffiliateSignup
      });
      return;
    }

    handleFreeCommunitySignup({ isForAffiliateSignup }); // TODO
  };

  const initBookSlot = (params) => {
    setIsBookingSlot(true);
    const { sessionStartTime, sessionEndTime } = params;
    // Send data to Analytics
    trackBookBtnClick({
      sessionStartTime,
      sessionEndTime
    });
    /**
     *
     * - is community manager
     *  - show cm cannot book toast ?
     * - is cm accepting bookings
     *  - show cm not accepting bookings toast
     * - is checkout required
     *   - go to checkout
     * - is user logged in
     *  - free session signup
     * - open signup modal
     */
    setSelectedSessionStartTime(sessionStartTime);
    setSelectedSessionEndTime(sessionEndTime);

    if (isCommunityAdmin) {
      // show cm cannot book toast
      showErrorToast(t('community-managers-cannot-book-sessions'));
      setIsBookingSlot(false);

      return;
    }

    if (resourceInfo?.stopAcceptingBookings) {
      // show cm not accepting bookings toast
      showErrorToast(t('cm-not-accepting-bookings', { cmName: hostName }));
      setIsBookingSlot(false);
      return;
    }

    if (isCheckoutRequired) {
      handleGoToCheckout(params);
      return;
    }

    if (isLoggedIn) {
      const withCommunitySignup = isCommunityMember ? false : true;

      handleFreeSessionBooking(params, withCommunitySignup);
      return;
    }

    // not a member
    setIsSignUpModalOpened(true);
    setIsBookingSlot(false);
  };

  const renderRightSideContent = () => {
    /*
      - user has an upcoming || ongoing booking ?
        - yes
          render upcoming || ongoing booking card - diff in online + inperson
        - no
          - is cm acceping bookings ?
            - yes
              - are booking slots available ?
                - yes
                  render booking slots
                - no
                  render cm is not accepting bookings card
            - no
              render cm is not accepting bookings card
      */

    if (hasUpcomingBookings) {
      return (
        <UpcomingBookingCard
          upcomingBookingData={upcomingBooking}
          onJoinClick={handleJoinBtnClick}
        />
      );
    }

    if (resourceInfo?.stopAcceptingBookings) {
      return <NotAcceptingBookingsCard cmName={hostName} />;
    }

    if (areSlotsAvailable && sessionSlots) {
      return (
        <BookAvailableSlotsCard
          durationInMins={resourceInfo?.durationIntervalInMinutes}
          sessionSlots={sessionSlots}
          onBookSlotClick={initBookSlot}
          selectedTimeZoneId={selectedTimeZoneId}
          setSelectedTimeZoneId={handleChangeTimezone}
          isBookingSlot={isBookingSlot}
          // analytics
          trackLeftNavArrowClickOnBookingDays={
            trackLeftNavArrowClickOnBookingDays
          }
          trackRightNavArrowClickOnBookingDays={
            trackRightNavArrowClickOnBookingDays
          }
          trackChangeBookingDate={trackChangeBookingDate}
          trackChangeBookingTime={trackChangeBookingTime}
        />
      );
    }

    if (!isFetchingSlots && !areSlotsAvailable) {
      return <NoSlotsAvailableCard cmName={hostName} />;
    }

    return null;
  };

  const renderMobileBottomSticky = () => {
    if (hasUpcomingBookings) {
      return (
        <MobileUpcomingBookingCard
          upcomingBookingData={upcomingBooking}
          onJoinClick={handleJoinBtnClick}
        />
      );
    }

    if (resourceInfo?.stopAcceptingBookings) {
      return <MobileNotAcceptingBookingsCard cmName={hostName} />;
    }

    if (areSlotsAvailable && sessionSlots) {
      const nextAvailableDateString =
        getNextAvailableSessionDateString(sessionSlots);

      return (
        <MobileNextAvailableSessionCard
          onViewDatesClick={() => {
            setShowSelectBookingSlotPopup(true);
            trackMobileViewDatesClick();
          }}
          nextAvailableDateString={nextAvailableDateString}
        />
      );
    }

    if (!isFetchingSlots && !areSlotsAvailable) {
      return <MobileNoSlotsAvailable cmName={hostName} />;
    }

    return null;
  };

  // Show booking success modal if withBookingSuccessModal is true in query params
  useEffect(() => {
    if (withBookingSuccessModal && isProfileComplete) {
      if (showSignupSuccessPrompt) {
        // delay opening this modal if signup success prompt is also showing
        setTimeout(() => {
          setShowSessionBookingSuccessModal(true);
        }, 3500);
      } else {
        setShowSessionBookingSuccessModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    withBookingSuccessModal,
    showSignupSuccessPrompt,
    isProfileComplete
  ]);

  return (
    <div
      className={classNames('c-OneOnOneLandingPage relative', {
        'bg-white-default': isSignUpModalOpened
      })}>
      <PageMetaTags {...metaTagProps} />

      <div
        className={
          'absolute left-0 top-0 z-[-2] w-full overflow-hidden h-[360px]'
        }>
        <NPLGlassImageBackground src={resourceInfo?.thumbnail} />
      </div>

      {/* Navbar */}
      {!loadingUserRoles && !isLoggedIn && (
        <GenericNavBar
          communityInfo={communityInfo}
          openSignUpModal={openSignUpModal}
          transition={{
            start: 16,
            end: 32
          }}
        />
      )}
      <div className="lg:w-[1020px] lg:mx-auto">
        <div className="p-16 md:px-0">
          <BackAndShareBtnBar
            entityTitle={resourceInfo?.title}
            entityType="session"
            communityImageSrc={communityInfo?.profileImage}
            communityLink={communityInfo?.link}
            communityTitle={communityInfo?.title}
            isLoggedIn={isLoggedIn}
          />
        </div>
        <div className="lg:flex lg:space-x-[30px]">
          {/* Left */}
          <div className="lg:w-[660px]">
            {/* Banner Image */}
            <BannerImage resourceInfo={resourceInfo} />
            {/* Host Profile Image */}
            <HostProfileImage hostInfo={resourceInfo?.hostInfo} />
            {/* Title, Price and Metadata */}
            <Overview
              resourceInfo={resourceInfo}
              discountedOneOnOneSessionPrice={
                discountedOneOnOneSessionPrice
              }
            />
            {/* About the session and host */}
            <div className="px-24 pb-40 lg:px-32">
              {/* About the session */}
              <AboutOneOnOneSession
                communityDescription={resourceInfo?.description}
              />
              {/* About the host */}
              <AboutTheHost hostInfo={resourceInfo?.hostInfo} />

              {/* Join As Affiliate Card */}
              {showJoinAsAffiliateCard && (
                <div className="affiliate-card-section mx-24 my-32 md:mx-auto md:max-w-[566px]">
                  <BecomeAnAffiliateCard
                    isCommunityMember={isCommunityMember}
                    onClick={onOpenJoinAsAffiliateModal}
                    communityId={communityId}
                    entityId={resourceInfo?._id}
                    entityType="SESSION"
                    affiliateCode={affiliateCode}
                  />
                </div>
              )}

              {/* You're an Affiliate Card */}
              {showYouAreAnAffiliateCard && (
                <div className="affiliate-card-section mx-24 my-32 md:mx-auto md:max-w-[566px]">
                  <YoureAnAffiliateOfTheProductCard />
                </div>
              )}
            </div>
          </div>
          {/* Desktop Cards on Right */}
          {isGtEqLg && (
            <div className="lg:w-[360px]">
              <div className="sticky top-[72px]">
                {renderRightSideContent()}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Bottom sticky states */}
      {!isGtEqLg && (
        <div className="fixed bottom-0 w-full border-t-1 z-over-intercom border-npl-neutral-light-solid-4 shadow-npl-styles-shadow-03">
          {renderMobileBottomSticky()}
        </div>
      )}
      {/* Mobile date selection popup */}
      {!isGtEqLg && (
        <SelectBookingSlotPopup
          open={showSelectBookingSlotPopup}
          durationInMins={resourceInfo?.durationIntervalInMinutes}
          sessionSlots={sessionSlots}
          onBookSlotClick={initBookSlot}
          selectedTimeZoneId={selectedTimeZoneId}
          setSelectedTimeZoneId={handleChangeTimezone}
          onClose={() => setShowSelectBookingSlotPopup(false)}
        />
      )}
      {/* Footer */}
      <PublicPageFooter
        templateContentType={templateContentType}
        isCommunityAdmin={isCommunityAdmin}
        communitySlug={communityInfo?.link}
      />
      {/* Modals */}
      <CommunitySignupModal
        open={isSignUpModalOpened}
        onClose={onSignUpPopupClose}
        communityInfo={{ ...communityInfo, pricingData }}
        signUpOriginProps={
          isSignupModalOnlyForCommunity
            ? {}
            : {
                isPaid: resourceInfo?.access === 'paid',
                signUpOrigin: SIGNUP_ORIGIN.ONE_ON_ONE_SESSION_PAGE,
                resourceInfo: resourceInfo,
                entityMetadata
              }
        }
        priceTagText={priceTagText}
        discountCodeFromQueryParams={discountCodeFromQueryParams}
        newMemberSignupCallback={handlePostFreeCommunitySignup}
        existingMemberLoginCallback={handlePostFreeCommunitySignup}
        isSignupModalToBecomeAffiliate={isSignupModalToBecomeAffiliate}
      />
      {showSessionBookingSuccessModal && upcomingBooking ? (
        <SessionBookingSuccessModal
          open={true}
          onClose={() => {
            setShowSessionBookingSuccessModal(false);

            // delete withBookingSuccessModal from currentRouterQuery
            const updatedQuery = { ...currentRouterQuery };
            delete updatedQuery.withBookingSuccessModal;
            replaceRoute(updatedQuery);
          }}
          bookingData={upcomingBooking}
        />
      ) : null}
      <WithPendingCommMembershipApprovalModal />
      <WithWelcomeToCommunitySplashScreen communityInfo={communityInfo} />
      {!currentRouterQuery?.showSignupSuccessPrompt &&
        !currentRouterQuery?.showMembershipPendingApproval &&
        (isCommunityMember || isPendingApproval) && (
          <CompleteYourProfileModalWrapper
            onProfileUpdateComplete={() =>
              setShowSessionBookingSuccessModal(true)
            }
          />
        )}

      {showJoinAsAffiliateModalExtra && (
        <BecomeAnAffiliateModal
          onClose={onCloseJoinAsAffiliateModal}
          isLoggedIn={isLoggedIn}
          userProfileImgSrc={user?.learner?.profileImage}
          communityId={communityId}
          communityTitle={communityInfo?.title}
          communityProfileImgSrc={communityInfo?.profileImage}
          isCommunityMember={isCommunityMember}
          onJoinCommunity={() =>
            handleJoinAsMember({
              isForAffiliateSignup: true
            })
          }
          onJoinAsAffiliate={onJoinAsAffiliate}
          isSubmittingJoinAsAffiliate={isSubmittingJoinAsAffiliate}
        />
      )}
    </div>
  );
};

export default OneOnOneLandingPage;
