import React from 'react';

import config from '@/utility/config';
import { getDefaultStaticProps } from '@/utility/getDefaultProps';
import { DB_RESOURCE_PUBLIC_PAGE_CONTENT_TYPE_ID } from '@/utility/pageTypes';

import OneOnOneLandingPage from '@/features/1on1Sessions/components/landing-page/OneOnOneLandingPage';
import ResourcePage from '@/features/ResourcePage';

import {
  getCommunityDataService,
  getResourceBySlug
} from '@/services/communitiesService';

import AuthNavbarPageLayout from '@/components/features/Navbar/AuthNavbarPageLayout';

import { notFoundPageWithRevalidation } from '../constants';

export async function getStaticPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getStaticProps(ctx) {
  const defaultStaticProps = await getDefaultStaticProps(ctx);
  const { communityLink, productSlug } = ctx.params;

  const communitySlug = `/${communityLink}`;

  const res = await getCommunityDataService(communitySlug);

  const { error } = res;

  // if a community doesn't even exist, don't need to check for product
  if (error) {
    return notFoundPageWithRevalidation;
  }

  // Fetch data for the specific product slug
  const { data: productData, error: productError } =
    await getResourceBySlug(`${communityLink}/${productSlug}`);

  //If the community is inactive, the resource will be not available, return 404
  if (
    productData &&
    productData.communityInfo &&
    !productData.communityInfo.isActive
  ) {
    return notFoundPageWithRevalidation;
  }

  // if productData exists, return product page
  if (productData && !productError) {
    const pageInfo = {
      templateData: {
        ...productData,
        communitySlug,
        productSlug
      },
      templateContentType: DB_RESOURCE_PUBLIC_PAGE_CONTENT_TYPE_ID
    };

    return {
      props: { pageInfo, ...defaultStaticProps?.props },
      revalidate: config.revalidateSec
    };
  }

  // else just return notFound
  return notFoundPageWithRevalidation;
}

const ProductPage = (props) => {
  const { pageInfo } = props;

  const { templateData } = pageInfo || {};

  if (templateData?.resourceInfo?.type === 'SESSION') {
    return <OneOnOneLandingPage {...props} />;
  }
  return <ResourcePage {...props} />;
};

ProductPage.getLayout = function getLayout(page) {
  const pageInfo = page?.props?.pageInfo?.templateData;

  return (
    <AuthNavbarPageLayout
      communityInfo={pageInfo?.communityInfo}
      transition={{
        start: 16,
        end: 32
      }}>
      {(userCommunitiesData) =>
        React.cloneElement(page, { userCommunitiesData })
      }
    </AuthNavbarPageLayout>
  );
};

export default ProductPage;
